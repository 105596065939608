
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserAttribute,
	CognitoUserPool,
	CognitoUserSession,
} from 'amazon-cognito-identity-js'
import { ADMINS } from '../constants';

export const poolData = {
	UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? '',
	ClientId: process.env.REACT_APP_CLIENT_ID ?? '',
};

export const userPool = new CognitoUserPool(poolData);
let cognito;
// cognito login
export const CognitoLogin = async (
	email,
	password
) => {
	return await new Promise((resolve, reject) => {
		const authenticationDetails = new AuthenticationDetails({
			Username: email,
			Password: password,
		});
		cognito = new CognitoUser({ Username: email, Pool: userPool });
		cognito.authenticateUser(authenticationDetails, {
			onSuccess: async (result) => {
				resolve(result);
			},
			onFailure: (error) => {
                console.log(error,"error");
				reject(error);
			},
			newPasswordRequired: (userAttributes, requiredAttributes) => {
				// if (userAttributes['custom:roleId'] !== '1') {
				//   cognitoUser.signOut();
				//   callToast(ToastTypes.ERROR, 'User does not exist');
				//   setLoading(false);
				//   return;
				// }
				resolve(userAttributes);
			  },
		});
	});
};

// cognito to get session
export async function GetCognitoSession() {
	return await new Promise((resolve, reject) => {
		const user = userPool.getCurrentUser();
		if (user != null) {
			user.getSession((error, session) => {
				if (error !== null) {
					reject(error);
				} else {
					if (session.isValid() === true) {
						const cognitoIdToken = session.getIdToken().getJwtToken();
					   localStorage.setItem('dashboard_auth_token', cognitoIdToken);
						resolve(true);
					} else {
						resolve(false);
					}
				}
			});
		} else {
			resolve(false);
		}
	});
}

export const CognitoLogout = () => {
	const user = userPool.getCurrentUser();
	if (user != null) {
		user.signOut();
		localStorage.removeItem('company_email');
		localStorage.removeItem('current_user_id');
		localStorage.removeItem('company_id');
		localStorage.removeItem('current_company');
		localStorage.removeItem('dashboard_auth_token');
		localStorage.removeItem('isAdmin');
		localStorage.removeItem('userData');
	}
};

export async function completeNewPasswordChallenge(newPassword) {
	return await new Promise((resolve, reject) => {
		const user = userPool.getCurrentUser();
		if (cognito) {
			cognito.completeNewPasswordChallenge(
				newPassword,
				{},
				{
				  onSuccess: (result) => {
					// const { payload, jwtToken } = result.idToken;
					// const { token } = result.refreshToken;
					// let userInfo = {
					//   email: payload.email,
					//   exp: payload.exp,
					//   token: jwtToken,
					//   refreshToken: token,
					//   userId: payload['custom:userId'],
					// };
					// localStorage.setItem('userData', JSON.stringify(userInfo));
					resolve(result);
				  },
				  onFailure: (err) => {
					console.log(err,"error");
					reject(err);
				  },
				},
			  );
		} else {
			resolve(false);
		}

	})
}