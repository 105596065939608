import React,{useContext, useEffect} from 'react'
import { UserContext } from '../Context/AuthContex';
import {Redirect} from "react-router-dom"
const AuthGaurd = ({children}) => {
       const { sessionLoader,sessionStatus} = useContext(
		UserContext
	) ;


    if(sessionLoader){
        return<></>
    }else if(sessionStatus){
       return(
        <>
       {children}
        </>
       )
    }else {
    return (
    <Redirect to={"/login"} replace={true}/>
  )
    }
  
}

export default AuthGaurd