import React, { useContext } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import AuthGaurd from './AuthGaurd/AuthGaurd';
import { UserContext } from './Context/AuthContex';
import LoadingScreen from './views/Pages/LoadingScreen';
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Redirecting = React.lazy(() => import('./views/Pages/Redirecting'));
const ForgotPassword=React.lazy(()=> import('./views/Pages/ForgotPassword'));
const App = () => {
  const { sessionLoader } = useContext(UserContext);
  return (
    <>
      {sessionLoader && <LoadingScreen />}
      <HashRouter>
        <React.Suspense fallback={<div className="animated fadeIn pt-3 text-center">Loading</div>}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            {/* <Route exact path="/forgot-password" name="Forgot Password Page" render={(props) => <ForgotPassword {...props} />} /> */}
            {/* <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => (
                <AuthGaurd>
                  <Register {...props} />
                </AuthGaurd>
              )}
            /> */}
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => (
                <AuthGaurd>
                  <Page404 {...props} />
                </AuthGaurd>
              )}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => (
                <AuthGaurd>
                  <Page500 {...props} />
                </AuthGaurd>
              )}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => (
                <AuthGaurd>
                  <DefaultLayout {...props} />
                </AuthGaurd>
              )}
            />
            <Route
              exact
              path="/redirecting"
              name="Redirecting"
              render={(props) => (
                <AuthGaurd>
                  <Redirecting {...props} />
                </AuthGaurd>
              )}
            />
          </Switch>
          <ToastContainer />
        </React.Suspense>
      </HashRouter>
    </>
  );
};
export default App;
