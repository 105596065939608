import React, { useState } from 'react';
import { CognitoLogout, GetCognitoSession } from '../CognitoServices/cognitoServices';

export const UserContext = React.createContext();

export const UserContextProvider = ({ children }) => {
  const [sessionStatus, setSessionStatus] = useState(false);
  const [sessionLoader, setSessionLoader] = useState(true);

  const handleSession = async () => {
    setSessionLoader(true);
    try {
      const res = await GetCognitoSession();
      res ? setSessionStatus(res) : handleLogout();
    } catch (err) {
      handleLogout();
    } finally {
      setSessionLoader(false);
    }
  };

  React.useEffect(() => {
    handleSession();
  }, []);

  const handleLogout = () => {
    CognitoLogout();
    setSessionStatus(false);
    localStorage.removeItem('company_email');
		localStorage.removeItem('current_user_id');
		localStorage.removeItem('company_id');
		localStorage.removeItem('current_company');
		localStorage.removeItem('dashboard_auth_token');
		localStorage.removeItem('isAdmin');
		localStorage.removeItem('userData');
  };

  return (
    <UserContext.Provider
      value={{
        sessionStatus,
        setSessionStatus,
        handleLogout,
        sessionLoader,
      }}>
      {children}
    </UserContext.Provider>
  );
};
